<template>
  <v-dialog v-model="dialogSupplier" persistent max-width="1000px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-container fluid>
          <!-- Entry Info section -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.selectSupplier") }}
              </span>
            </v-col>
          </v-row>

          <v-sheet class="mt-5">
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-toolbar-items>
          </v-sheet>

          <!-- Expandable Table -->
          <v-data-table
            :headers="headers"
            :single-select="true"
            :items="datas"
            :items-per-page="10"
            :search="search"
            mobile-breakpoint="960"
            show-select
            v-model="selected"
          />
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />

        <v-btn color="red darken-2" outlined @click="cancelForm">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="blue darken-2" @click="handleSelectedData">
          {{ $vuetify.lang.t("$vuetify.confirm") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { supplierList } from "@/api/basicdata";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

export default {
  name: "SelectSupplier",
  props: { dialogSupplier: Boolean, supplierObj: Object },
  data() {
    return {
      search: "",
      datas: [],
      selected: [],
      types: ["All", "A9 Online", "Testing"],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.companyName"),
          value: "company",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contact",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.phone"),
          value: "tel",
          width: "120",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addr"),
          value: "address",
          width: "300",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialogSupplier", false);

      this.search = "";
      this.datas = [];
      this.selected = [];
    },
    getData() {
      supplierList()
        .then((data) => {
          this.datas = [...data.items];

          this.datas.forEach((p, k) => (p.itemNo = k + 1));
        })
        .catch((err) => console.log("Supplier List Error", err));
    },
    async handleSelectedData() {
      var temp = null;

      temp = await renameKey(this.selected[0], "company", "supplier");

      temp = await renameKey(this.selected[0], "id", "supplier_id");

      this.$emit("update:supplierObj", temp);
      this.$emit("update:dialogSupplier", false);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogSupplier() {
      this.dialogSupplier ? this.getData() : "";
    },
  },
};
</script>